<script setup>
import { computed, ref, watchEffect } from 'vue';
import { hideBodyScroll } from '@/utils/functions'
import pdf from 'vue-pdf'

const props = defineProps({
    billId: {
        type: [String, Number],
        default: null
    },
    receiptNumber: {
        type: [String, Number],
        default: null
    },
    url: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null,
    },
    open: {
        type: Boolean,
        default: false
    },
})

const emit = defineEmits(['update:open'])

const dialogModel = computed({
    get() {
        return props.open
    }, set(newVal) {
        hideBodyScroll(false)
        emit('update:open', newVal)
    }
})

watchEffect(() => {
    const dialogOpen = dialogModel.value === true
    if (dialogOpen) {
        hideBodyScroll()
    } else {
        hideBodyScroll(false)
    }
})

const pdfLoaded = ref(false)
const billPdfComponent = ref(null)
const title = ref('')
const pdfUrl = ref(null)

watchEffect(() => {
    const billIdNotEmpty = props.billId !== null
    const receiptNumberNotEmpty = !!props.receiptNumber
    let url
    if (billIdNotEmpty) {
        url = `${process.env.VUE_APP_API_URL}/api/client/bill/generate-bill-pdf?bill_id=${props.billId}`
        title.value = 'Bill'
    } else if (receiptNumberNotEmpty) {
        url = `${process.env.VUE_APP_API_URL}/api/client/receipt/${props.receiptNumber}`
        title.value = 'Receipt'
    } else {
        url = props.url
        title.value = props.title
    }

    pdfUrl.value = {
        url,
        httpHeaders: {
            Authorization: localStorage.getItem('user-token'),
        }
    }
})

function onPdfLoaded() {
    pdfLoaded.value = true
}

function onPrintClicked() {
    billPdfComponent.value?.print()
}
</script>
<template>
    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialogModel">
        <v-card flat>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" class="px-0 pt-0">
                        <v-toolbar flat dark color="primary">
                            <v-btn icon dark @click="dialogModel = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ title }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col v-if="!pdfLoaded" cols="12" class="px-6">
                        <v-progress-linear indeterminate />
                    </v-col>
                </v-row>

                <v-row v-if="pdfLoaded" justify-md="end">
                    <v-col cols="auto" class="px-8">
                        <v-btn class="text-none" color="success" @click="onPrintClicked">
                            <span>Print</span><v-icon right>mdi-printer</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <pdf ref="billPdfComponent" :src="pdfUrl" @loaded="onPdfLoaded"></pdf>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>